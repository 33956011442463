export const getImages = async () => {
  const res = await fetch('https://0x77.art/api').catch((err) => {
    console.warn(err)
    return {
      json: () => ["https://cdn.0x77.art/0.png","https://cdn.0x77.art/1.png","https://cdn.0x77.art/2.png","https://cdn.0x77.art/3.png","https://cdn.0x77.art/4.png","https://cdn.0x77.art/5.png","https://cdn.0x77.art/6.png","https://cdn.0x77.art/7.png","https://cdn.0x77.art/8.png","https://cdn.0x77.art/9.png"]
    }
  })

  return res.json() as Promise<string[]>
}
