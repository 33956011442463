import * as THREE from 'three'
import { Suspense, useEffect, useRef, useState } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Preload, Image as ImageImpl } from '@react-three/drei'
import { ScrollControls, Scroll, useScroll } from './ScrollControls'
import { getImages } from './api'

function Image(props) {
  const ref = useRef()
  const group = useRef()
  const data = useScroll()
  useFrame((state, delta) => {
    group.current.position.z = THREE.MathUtils.damp(group.current.position.z, Math.max(0, data.delta * 50), 4, delta)
    ref.current.material.grayscale = THREE.MathUtils.damp(ref.current.material.grayscale, Math.max(0, 1 - data.delta * 1000), 4, delta)
  })
  return (
    <group ref={group}>
      <ImageImpl ref={ref} {...props} />
    </group>
  )
}

function Page({ m = 0.4, urls, ...props }) {
  const { width } = useThree((state) => state.viewport)
  const w = width < 10 ? 1.5 / 3 : 1 / 3
  return (
    <group {...props}>
      <Image position={[-width * w, 0, -1]} scale={[width * w - m * 2, 5, 1]} url={urls[0]} />
      <Image position={[0, 0, 0]} scale={[width * w - m * 2, 5, 1]} url={urls[1]} />
      <Image position={[width * w, 0, 1]} scale={[width * w - m * 2, 5, 1]} url={urls[2]} />
    </group>
  )
}

function Pages() {
  const [images, setImages] = useState(["https://cdn.0x77.art/0.png", "https://cdn.0x77.art/1.png", "https://cdn.0x77.art/2.png", "https://cdn.0x77.art/3.png", "https://cdn.0x77.art/4.png", "https://cdn.0x77.art/5.png", "https://cdn.0x77.art/6.png", "https://cdn.0x77.art/7.png", "https://cdn.0x77.art/8.png", "https://cdn.0x77.art/9.png"])

  useEffect(() => {
    getImages().then((images) => { setImages(images) })
  }, [])

  const { width } = useThree((state) => state.viewport)

  return (
    <>
      <Page position={[-width * 1, 0, 0]} urls={images.slice(0, 3)} />
      <Page position={[width * 0, 0, 0]} urls={images.slice(-3)} />
      <Page position={[width * 1, 0, 0]} urls={images.slice(3, 6).reverse()} />
      <Page position={[width * 2, 0, 0]} urls={images.slice(6, 9)} />
      <Page position={[width * 3, 0, 0]} urls={images.slice(3, 6)} />
      <Page position={[width * 4, 0, 0]} urls={images.slice(0, 3)} />
    </>
  )
}

export default function App() {
  return (
    <Canvas style={{ filter: 'brightness(0.95)' }} gl={{ antialias: false }} dpr={[1, 1.5]}>
      <Suspense fallback={null}>
        <ScrollControls infinite horizontal damping={4} pages={6} distance={1}>
          <Scroll>
            <Pages />
          </Scroll>
          <Scroll html>
            <h1 style={{ position: 'absolute', color: 'whitesmoke', top: '12.5vh', left: '12vw' }}>AI Art</h1>
            <h1 style={{ position: 'absolute', color: 'whitesmoke', top: '12.5vh', left: '90vw' }}>Using brain</h1>
            <h1 style={{ position: 'absolute', color: 'whitesmoke', top: '20vh', left: '170vw' }}>EEG Driven</h1>
            <h1 style={{ position: 'absolute', color: 'whitesmoke', top: '20vh', left: '300vw' }}>War Performance</h1>
            <h1 style={{ position: 'absolute', color: 'whitesmoke', top: '45vh', left: '550vw', fontSize: '5em' }}>
              👉
            </h1>
          </Scroll>
        </ScrollControls>
        <Preload />
      </Suspense>
    </Canvas>
  )
}
