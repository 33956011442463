import ReactDOM from 'react-dom'
import './styles.css'
import App from './App'

function Overlay() {
  return (
    <div style={{ position: 'absolute', color: 'white', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%' }}>
      <a href="https://instagram.com/0x77art" style={{ position: 'absolute', bottom: 40, left: 90, fontSize: '13px' }}>
        @0x77art
        <br />
        Follow on Instagram / Images generated using AI (DALL-E)
      </a>
      <div style={{ position: 'absolute', top: 40, left: 40, fontSize: '13px' }}>
        <a href="https://0x77.dev/links">Mykhailo Marynenko</a> x <a href="http://nadiaburiachok.art">Nadia Buriachok</a>
        <hr />
        AI/EEG Live Visual Performance
        <br /> Stay tuned for updates!
      </div>
    </div>
  )
}

ReactDOM.render(
  <>
    <App />
    <Overlay />
  </>,
  document.getElementById('root')
)
